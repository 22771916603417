import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DailyMetric, TrackerState } from '../types/tracker';
import { trackerService } from '../services/trackerService';

export const useTrackerStore = create<TrackerState>()(
  persist(
    (set, get) => ({
      dailyMetrics: [],
      isLoading: false,
      error: null,
      
      initializeMetrics: async () => {
        try {
          set({ isLoading: true, error: null });
          const metrics = await trackerService.getMetrics(84); // Get 12 weeks of data
          set({ 
            dailyMetrics: metrics,
            isLoading: false 
          });
        } catch (error) {
          set({ 
            error: (error as Error).message,
            isLoading: false 
          });
        }
      },

      addDailyMetric: async (metric: DailyMetric) => {
        try {
          await trackerService.addMetric(metric);
          set((state) => ({
            dailyMetrics: [...state.dailyMetrics, metric]
          }));
        } catch (error) {
          set({ error: (error as Error).message });
        }
      },
      
      getMetricsForRange: (startDate: string, endDate: string) => {
        const { dailyMetrics } = get();
        return dailyMetrics.filter(metric => 
          metric.date >= startDate && metric.date <= endDate
        );
      }
    }),
    {
      name: 'tracker-storage',
    }
  )
);