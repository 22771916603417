import React from 'react';
import { Snowflake } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { TodayIcon } from './icons/TodayIcon';
import { TrackerIcon } from './icons/TrackerIcon';
import { TenWeekGoalsIcon } from './icons/TenWeekGoalsIcon';
import { MasteryIcon } from './icons/MasteryIcon';
import { ThemeToggle } from './ThemeToggle';
import { AuthButton } from './AuthButton';
import { useThemeStore } from '../store/themeStore';

export function Sidebar() {
  const location = useLocation();
  const { isDark } = useThemeStore();
  const iconColorClass = isDark ? 'text-gray-400' : 'text-gray-600';
  
  const iconContainerClass = `w-10 h-10 flex items-center justify-center rounded-md transition-colors`;
  const getActiveClass = (path: string) => 
    location.pathname === path 
      ? 'bg-gray-100 dark:bg-[#1a1a1a]' 
      : 'hover:bg-gray-100 dark:hover:bg-[#1a1a1a]';
  
  return (
    <div className="fixed left-0 top-0 flex h-full w-16 flex-col items-center bg-white dark:bg-[#111111] transition-colors py-4">
      <nav className="flex flex-1 flex-col items-center">
        <div className="space-y-4">
          <Link
            to="/"
            className={`${iconContainerClass} ${getActiveClass('/')}`}
          >
            <TenWeekGoalsIcon className={`h-7 w-7 ${iconColorClass}`} />
          </Link>
          
          <Link
            to="/icebox"
            className={`${iconContainerClass} ${getActiveClass('/icebox')}`}
          >
            <Snowflake className={`h-7 w-7 ${iconColorClass}`} />
          </Link>

          <Link
            to="/10wk-goals"
            className={`${iconContainerClass} ${getActiveClass('/10wk-goals')}`}
          >
            <TodayIcon className={`h-7 w-7 ${iconColorClass}`} />
          </Link>

          <Link
            to="/mastery"
            className={`${iconContainerClass} ${getActiveClass('/mastery')}`}
          >
            <MasteryIcon className={`h-7 w-7 ${iconColorClass}`} />
          </Link>
        </div>

        {/* Horizontal line break */}
        <div className="w-8 h-px bg-gray-200 dark:bg-gray-700 my-4" />

        <Link
          to="/tracker"
          className={`${iconContainerClass} ${getActiveClass('/tracker')}`}
        >
          <TrackerIcon className={`h-7 w-7 ${iconColorClass}`} />
        </Link>

        <div className="mt-auto space-y-4">
          <AuthButton />
          <ThemeToggle />
        </div>
      </nav>
    </div>
  );
}