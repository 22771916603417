import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { TodoList } from '../TodoList';
import { Tracker } from '../Tracker';
import { TenWeekGoals } from '../TenWeekGoals';
import { Mastery } from '../Mastery';
import { Sidebar } from '../Sidebar';

export function AppRoutes() {
  return (
    <>
      <Sidebar />
      <main className="ml-16 flex justify-center p-8">
        <Routes>
          <Route path="/" element={<TodoList />} />
          <Route path="/icebox" element={<TodoList isIcebox />} />
          <Route path="/tracker" element={<Tracker />} />
          <Route path="/10wk-goals" element={<TenWeekGoals />} />
          <Route path="/mastery" element={<Mastery />} />
        </Routes>
      </main>
    </>
  );
}