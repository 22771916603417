import React from 'react';

export function TrackerIcon({ className = "h-6 w-6 text-gray-400" }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      {/* Perforated vertical line */}
      <g strokeDasharray="2 2">
        <line x1="12" y1="4" x2="12" y2="20" />
      </g>
      
      {/* Perforated angled lines */}
      <g strokeDasharray="2 2">
        <line x1="12" y1="4" x2="6" y2="10" />
        <line x1="12" y1="4" x2="18" y2="10" />
      </g>
    </svg>
  );
}