import { useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { signInWithGoogle, logOut, subscribeToAuthChanges } from '../services/authService';
import { getAuthErrorMessage } from '../utils/errorHandlers';

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const unsubscribe = subscribeToAuthChanges((user) => {
      setUser(user);
      setLoading(false);
    });

    // Check for redirect result on mount
    const checkRedirect = async () => {
      try {
        await signInWithGoogle();
      } catch (err) {
        console.error('Redirect error:', err);
        setError(getAuthErrorMessage(err));
      }
    };

    if (window.location.search.includes('mode=redirect')) {
      checkRedirect();
    }

    return () => unsubscribe();
  }, []);

  const handleSignIn = async () => {
    try {
      setError(null);
      await signInWithGoogle();
    } catch (err) {
      console.error('Sign in error:', err);
      setError(getAuthErrorMessage(err));
    }
  };

  const handleLogout = async () => {
    try {
      setError(null);
      await logOut();
    } catch (err) {
      console.error('Sign out error:', err);
      setError(getAuthErrorMessage(err));
    }
  };

  return {
    user,
    loading,
    error,
    signInWithGoogle: handleSignIn,
    logout: handleLogout,
  };
}