import { useTodoStore } from '../store/todoStore';
import { useTrackerStore } from '../store/trackerStore';
import { calculateDailyPoints } from './points';
import { moveIncompleteTodosToIcebox } from './todoOperations';
import { deleteCompletedTodos } from './todoOperations';
import { resetTodoList } from './todoOperations';

export async function processDayEnd() {
  const todoStore = useTodoStore.getState();
  const trackerStore = useTrackerStore.getState();
  
  try {
    // Calculate and record points
    const { points, maxPoints } = calculateDailyPoints(todoStore.todos);
    await recordDailyMetric(points, maxPoints, trackerStore);
    
    // Process todos
    await moveIncompleteTodosToIcebox(todoStore);
    await deleteCompletedTodos(todoStore);
    await resetTodoList(todoStore);
    
  } catch (error) {
    console.error('Error processing day end:', error);
    throw error;
  }
}