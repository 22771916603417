import { setupAuthPersistence } from '../../services/authService';

export async function initializeAuth() {
  try {
    // Setup auth persistence
    await setupAuthPersistence();
    
    // Setup auth emulator in development if needed
    if (process.env.NODE_ENV === 'development') {
      const { connectAuthEmulator } = await import('firebase/auth');
      const { auth } = await import('../../config/firebase');
      connectAuthEmulator(auth, 'http://localhost:9099');
    }
  } catch (error) {
    console.error('Error initializing auth:', error);
    throw error;
  }
}