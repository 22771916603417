import { processDayEnd } from './dayEndProcessor';

export function setupDailyScheduler() {
  const checkTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    
    // Check if it's 23:55 in the user's local timezone
    if (hours === 23 && minutes === 55) {
      processDayEnd();
    }
  };

  // Check every minute
  setInterval(checkTime, 60000);
}