import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { initializeApp } from '../../utils/initializeApp';

interface Props {
  children: React.ReactNode;
}

export function AppProvider({ children }: Props) {
  const { user } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function initialize() {
      try {
        await initializeApp();
        setIsInitialized(true);
      } catch (err) {
        console.error('Initialization error:', err);
        setError((err as Error).message);
      }
    }

    if (user) {
      initialize();
    } else {
      setIsInitialized(true);
    }
  }, [user]);

  if (!isInitialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-gray-100" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center text-red-500">
        Error: {error}
      </div>
    );
  }

  return <>{children}</>;
}