import React, { useEffect, useRef } from 'react';
import { useTrackerStore } from '../store/trackerStore';
import { useThemeStore } from '../store/themeStore';

export function Tracker() {
  const chartRef = useRef<HTMLDivElement>(null);
  const { isDark } = useThemeStore();
  const { dailyMetrics, initializeMetrics } = useTrackerStore();
  
  useEffect(() => {
    initializeMetrics();
  }, [initializeMetrics]);
  
  useEffect(() => {
    if (!chartRef.current) return;
    
    const chart = chartRef.current;
    const maxHeight = 200; // pixels
    
    // Clear previous content
    chart.innerHTML = '';
    
    // Get last 84 days of metrics (12 weeks)
    const recentMetrics = dailyMetrics.slice(-84);
    
    recentMetrics.forEach((metric) => {
      const bar = document.createElement('div');
      const heightPercentage = (metric.points / metric.maxPoints) * 100;
      const height = (heightPercentage * maxHeight) / 100;
      
      bar.className = `inline-block w-1 mx-px rounded-t transition-all duration-300 
        ${isDark 
          ? 'bg-gray-600 hover:bg-gray-400' 
          : 'bg-gray-400 hover:bg-gray-600'}`;
      bar.style.height = `${height}px`;
      bar.title = `${new Date(metric.date).toLocaleDateString()}: ${metric.points}/${metric.maxPoints} points`;
      
      chart.appendChild(bar);
    });
  }, [dailyMetrics, isDark]);

  return (
    <div className="w-full max-w-6xl">
      <h1 className="text-2xl font-bold mb-8 text-gray-800 dark:text-gray-300">
        Tracker
      </h1>
      
      <div className="bg-white dark:bg-[#1a1a1a] rounded-lg p-6 transition-colors">
        <div 
          ref={chartRef}
          className="h-[200px] w-full overflow-x-auto whitespace-nowrap pb-4 flex items-end"
        />
      </div>
    </div>
  );
}