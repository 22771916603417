import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { initializeAuth } from './utils/auth/initialize';
import { setupDailyScheduler } from './utils/scheduler';

// Initialize auth and scheduler
Promise.all([
  initializeAuth(),
  setupDailyScheduler()
]).catch(console.error);

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);