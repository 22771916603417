import React from 'react';

export function TodayIcon({ className = "h-6 w-6 text-gray-400" }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      {/* Four dots of varying sizes, vertically aligned */}
      <circle cx="12" cy="5" r="2.2" />
      <circle cx="12" cy="11" r="1.8" />
      <circle cx="12" cy="17" r="2" />
      <circle cx="12" cy="23" r="1.7" />
    </svg>
  );
}