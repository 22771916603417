import { TodoStore } from '../types/todo';

export async function moveIncompleteTodosToIcebox(todoStore: TodoStore) {
  const incompleteTodos = todoStore.todos.filter(todo => !todo.completed);
  for (const todo of incompleteTodos) {
    await todoStore.moveToIcebox(todo.id);
  }
}

export async function deleteCompletedTodos(todoStore: TodoStore) {
  const completedTodos = todoStore.todos.filter(todo => todo.completed);
  for (const todo of completedTodos) {
    await todoStore.deleteTodo(todo.id);
  }
}

export async function resetTodoList(todoStore: TodoStore) {
  await todoStore.resetTodos();
}