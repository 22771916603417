import { useCallback, useRef } from 'react';

export function useKeyboardNavigation() {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      e.preventDefault();
      const currentInput = e.target as HTMLInputElement;
      const allInputs = Array.from(document.querySelectorAll('input[type="text"]'));
      const currentIndex = allInputs.indexOf(currentInput);
      const nextInput = allInputs[currentIndex + 1];

      if (nextInput) {
        (nextInput as HTMLInputElement).focus();
      }
    }
  }, []);

  return { inputRef, handleKeyDown };
}