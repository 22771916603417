import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyDkY1c1FfJs2vNOx7NYu-PcuhDBeP9g9AY",
  authDomain: "eatsfrog-d5cfe.firebaseapp.com",
  projectId: "eatsfrog-d5cfe",
  storageBucket: "eatsfrog-d5cfe.firebasestorage.app",
  messagingSenderId: "230067436235",
  appId: "1:230067436235:web:c5950b062f3227e010b94e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const db = getFirestore(app);
export const auth = getAuth(app);

// Initialize providers
export const googleProvider = new GoogleAuthProvider();

// Configure Google provider
googleProvider.setCustomParameters({
  prompt: 'select_account'
});