import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { TodoState, Todo } from '../types/todo';
import { todoService } from '../services/todoService';

const getPointsForPosition = (index: number): number => {
  if (index === 0) return 10;
  if (index >= 1 && index <= 3) return 5;
  return 2;
};

const createEmptyTodo = (index: number, isIcebox: boolean = false): Omit<Todo, 'id'> => ({
  text: '',
  completed: false,
  points: getPointsForPosition(index),
  isIcebox,
  position: index,
});

const ensureEightTodos = async (todos: Todo[]): Promise<Todo[]> => {
  const newTodos = [...todos];
  
  // If we have more than 8 todos, keep only the first 8
  if (newTodos.length > 8) {
    return newTodos.slice(0, 8);
  }
  
  // If we have less than 8 todos, add empty ones
  while (newTodos.length < 8) {
    const newTodo = await todoService.addTodo(createEmptyTodo(newTodos.length));
    newTodos.push(newTodo);
  }
  
  return newTodos;
};

const calculateTotalPoints = (todos: Todo[]): number => {
  return todos.reduce((total, todo) => {
    return total + (todo.completed ? todo.points || 0 : 0);
  }, 0);
};

export const useTodoStore = create<TodoState>()(
  persist(
    (set, get) => ({
      todos: [],
      icebox: [],
      archive: [],
      totalPoints: 0,
      isLoading: true,
      error: null,

      initializeTodos: async () => {
        try {
          set({ isLoading: true, error: null });
          const [mainTodos, iceboxTodos] = await Promise.all([
            todoService.getTodos(false),
            todoService.getTodos(true)
          ]);
          
          const ensuredTodos = await ensureEightTodos(mainTodos);
          
          set({ 
            todos: ensuredTodos,
            icebox: iceboxTodos,
            isLoading: false,
            totalPoints: calculateTotalPoints(ensuredTodos)
          });
        } catch (error) {
          set({ error: (error as Error).message, isLoading: false });
          throw error;
        }
      },

      // ... rest of the store implementation remains the same
    }),
    {
      name: 'todo-storage',
    }
  )
);