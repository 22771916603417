import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ZeroItem {
  id: string;
  text: string;
}

interface ZeroState {
  items: ZeroItem[];
  updateItem: (id: string, text: string) => void;
  reorderItems: (activeId: string, overId: string) => void;
}

const createEmptyItem = (): ZeroItem => ({
  id: `zero-${Date.now()}-${Math.random()}`,
  text: '',
});

export const useZeroStore = create<ZeroState>()(
  persist(
    (set) => ({
      items: Array(3)
        .fill(null)
        .map(() => createEmptyItem()),

      updateItem: (id, text) =>
        set((state) => ({
          items: state.items.map((item) =>
            item.id === id ? { ...item, text } : item
          ),
        })),

      reorderItems: (activeId, overId) =>
        set((state) => {
          const oldIndex = state.items.findIndex((item) => item.id === activeId);
          const newIndex = state.items.findIndex((item) => item.id === overId);
          
          if (oldIndex === -1 || newIndex === -1) return state;

          const newItems = [...state.items];
          const [removed] = newItems.splice(oldIndex, 1);
          newItems.splice(newIndex, 0, removed);
          
          return { items: newItems };
        }),
    }),
    {
      name: 'zero-storage',
    }
  )
);