import React from 'react';
import { LogIn } from 'lucide-react';
import { useThemeStore } from '../../store/themeStore';
import { AuthError } from './AuthError';

interface Props {
  onSignIn: () => void;
  error: string | null;
}

export function SignInForm({ onSignIn, error }: Props) {
  const { isDark } = useThemeStore();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-4">
      <div className={`w-full max-w-md p-8 rounded-lg shadow-lg 
        ${isDark ? 'bg-gray-800' : 'bg-white'}`}
      >
        <h1 className={`text-2xl font-bold mb-8 text-center
          ${isDark ? 'text-gray-300' : 'text-gray-800'}`}
        >
          Welcome to EatsFrog
        </h1>

        {error && <AuthError message={error} />}

        <button
          onClick={onSignIn}
          className={`w-full flex items-center justify-center gap-2 px-6 py-3 rounded-lg 
            transition-colors font-medium
            ${isDark 
              ? 'bg-gray-700 hover:bg-gray-600 text-gray-300' 
              : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
            }`}
        >
          <LogIn className="h-5 w-5" />
          Sign in with Google
        </button>
      </div>
    </div>
  );
}