import { 
  signInWithPopup, 
  signInWithRedirect,
  getRedirectResult,
  signOut,
  onAuthStateChanged,
  browserLocalPersistence,
  setPersistence,
  User
} from 'firebase/auth';
import { auth, googleProvider } from '../config/firebase';
import { isAuthorizedDomain } from '../config/environment';
import { AUTH_ERRORS } from '../config/constants';

// Setup auth persistence
export async function setupAuthPersistence() {
  return setPersistence(auth, browserLocalPersistence);
}

export async function signInWithGoogle(): Promise<User | null> {
  try {
    // Verify domain authorization
    if (!isAuthorizedDomain(window.location.hostname)) {
      throw new Error('Unauthorized domain');
    }

    // Try popup first
    try {
      const result = await signInWithPopup(auth, googleProvider);
      return result.user;
    } catch (popupError: any) {
      // If popup blocked or in iframe, use redirect
      if (popupError.code === AUTH_ERRORS.POPUP_BLOCKED || window !== window.top) {
        await signInWithRedirect(auth, googleProvider);
        const result = await getRedirectResult(auth);
        return result?.user || null;
      }
      throw popupError;
    }
  } catch (error) {
    console.error('Authentication error:', error);
    throw error;
  }
}

export async function logOut(): Promise<void> {
  try {
    await signOut(auth);
  } catch (error) {
    console.error('Logout error:', error);
    throw error;
  }
}

export function subscribeToAuthChanges(callback: (user: User | null) => void): () => void {
  return onAuthStateChanged(auth, callback);
}

export function getCurrentUser(): User | null {
  return auth.currentUser;
}