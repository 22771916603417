import React, { useState, useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Check, MoveRight, GripVertical, Trash2 } from 'lucide-react';
import { Todo } from '../types/todo';
import { useKeyboardNavigation } from '../hooks/useKeyboardNavigation';
import { useThemeStore } from '../store/themeStore';

interface Props {
  todo: Todo;
  onToggle: (id: string) => void;
  onUpdate: (id: string, text: string) => void;
  onMoveToIcebox?: (id: string) => void;
  onMoveFromIcebox?: (id: string) => void;
  onDelete?: (id: string) => void;
  isIcebox?: boolean;
}

export function TodoItem({ 
  todo, 
  onToggle, 
  onUpdate, 
  onMoveToIcebox,
  onMoveFromIcebox,
  onDelete,
  isIcebox 
}: Props) {
  const { isDark } = useThemeStore();
  const [localText, setLocalText] = useState(todo.text);
  const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: todo.id });

  const { inputRef, handleKeyDown } = useKeyboardNavigation();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setLocalText(newText);
    
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    
    const timeout = setTimeout(() => {
      onUpdate(todo.id, newText);
    }, 500);
    
    setUpdateTimeout(timeout);
  }, [todo.id, onUpdate, updateTimeout]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`group relative flex items-center gap-4 py-3 px-4 border
        ${isDark ? 'border-gray-700' : 'border-gray-200'}
        ${isDragging ? 'opacity-50 bg-gray-100 dark:bg-[#1a1a1a] rounded-lg' : 'hover:bg-gray-100/50 dark:hover:bg-[#1a1a1a]/50 rounded-lg transition-colors'}`}
    >
      <button
        {...attributes}
        {...listeners}
        className="cursor-grab active:cursor-grabbing p-1 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        type="button"
      >
        <GripVertical className={`h-5 w-5 ${isDark ? 'text-gray-600' : 'text-gray-400'}`} />
      </button>

      <div className="flex-1">
        <input
          ref={inputRef}
          type="text"
          value={localText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="What needs to be done?"
          className={`w-full bg-transparent placeholder-gray-400 dark:placeholder-gray-600 focus:outline-none pr-24
            ${isDark ? 'text-gray-300' : 'text-gray-700'}
            ${todo.completed ? 'line-through text-gray-400 dark:text-gray-500' : ''}`}
        />
      </div>
      
      <div className="absolute right-4 flex items-center gap-2 opacity-0 group-hover:opacity-100">
        {!isIcebox && (
          <button
            type="button"
            className={`flex h-8 w-8 items-center justify-center rounded-md
              ${isDark ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}
              transition-colors
              ${todo.completed ? (isDark ? 'bg-gray-800' : 'bg-gray-100') : ''}`}
            onClick={() => onToggle(todo.id)}
          >
            {todo.completed && <Check className={`h-5 w-5 ${isDark ? 'text-gray-300' : 'text-gray-600'}`} />}
          </button>
        )}
        
        {onMoveToIcebox && !todo.completed && (
          <button
            type="button"
            onClick={() => onMoveToIcebox(todo.id)}
            className={`flex h-8 w-8 items-center justify-center rounded-md ${isDark ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
            title="Move to Ice Box"
          >
            <MoveRight className={`h-5 w-5 ${isDark ? 'text-gray-600 hover:text-gray-400' : 'text-gray-400 hover:text-gray-600'}`} />
          </button>
        )}
        
        {onMoveFromIcebox && (
          <button
            type="button"
            onClick={() => onMoveFromIcebox(todo.id)}
            className={`flex h-8 w-8 items-center justify-center rounded-md ${isDark ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
            title="Move to Today"
          >
            <MoveRight className={`h-5 w-5 ${isDark ? 'text-gray-600 hover:text-gray-400' : 'text-gray-400 hover:text-gray-600'}`} />
          </button>
        )}

        {isIcebox && onDelete && (
          <button
            type="button"
            onClick={() => onDelete(todo.id)}
            className={`flex h-8 w-8 items-center justify-center rounded-md ${isDark ? 'hover:bg-gray-800' : 'hover:bg-gray-100'}`}
            title="Delete Todo"
          >
            <Trash2 className={`h-5 w-5 ${isDark ? 'text-gray-600 hover:text-gray-400' : 'text-gray-400 hover:text-gray-600'}`} />
          </button>
        )}
      </div>
    </div>
  );
}