import React from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useMasteryStore } from '../store/masteryStore';
import { MasteryItem } from './MasteryItem';
import { useThemeStore } from '../store/themeStore';

export function Mastery() {
  const { isDark } = useThemeStore();
  const { items, updateItem, reorderItems } = useMasteryStore();
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor)
  );

  return (
    <div className="w-full max-w-2xl">
      <h1 className="text-2xl font-bold mb-8 text-gray-800 dark:text-gray-300">
        Mastery
      </h1>
      
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => {
          const { active, over } = event;
          if (over && active.id !== over.id) {
            reorderItems(active.id as string, over.id as string);
          }
        }}
      >
        <SortableContext
          items={items.map(item => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-2">
            {items.map((item) => (
              <MasteryItem
                key={item.id}
                item={item}
                onUpdate={updateItem}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </div>
  );
}