const environments = {
  development: {
    apiUrl: 'http://localhost:5173',
    netlifyUrl: 'https://astounding-shortbread-6a3e1d.netlify.app',
    authorizedDomains: [
      'localhost',
      'stackblitz.com',
      'astounding-shortbread-6a3e1d.netlify.app'
    ]
  },
  production: {
    apiUrl: 'https://astounding-shortbread-6a3e1d.netlify.app',
    netlifyUrl: 'https://astounding-shortbread-6a3e1d.netlify.app',
    authorizedDomains: [
      'astounding-shortbread-6a3e1d.netlify.app'
    ]
  }
} as const;

const environment = process.env.NODE_ENV === 'production' ? 'production' : 'development';

export const config = environments[environment];

export const isAuthorizedDomain = (domain: string): boolean => {
  return config.authorizedDomains.some(authorizedDomain => 
    domain.includes(authorizedDomain)
  );
};