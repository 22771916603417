import { 
  collection, 
  addDoc, 
  getDocs,
  query,
  where,
  orderBy,
  limit 
} from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import { DailyMetric } from '../types/tracker';

const METRICS_COLLECTION = 'dailyMetrics';

export const trackerService = {
  async getMetrics(limitCount: number = 84) {
    if (!auth.currentUser) return [];
    
    const q = query(
      collection(db, METRICS_COLLECTION),
      where('userId', '==', auth.currentUser.uid),
      orderBy('date', 'desc'),
      limit(limitCount)
    );
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as DailyMetric[];
  },

  async addMetric(metric: DailyMetric) {
    if (!auth.currentUser) throw new Error('User not authenticated');
    
    const docRef = await addDoc(collection(db, METRICS_COLLECTION), {
      ...metric,
      userId: auth.currentUser.uid,
      createdAt: new Date().toISOString()
    });
    return { id: docRef.id, ...metric };
  }
};