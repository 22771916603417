import React, { useEffect } from 'react';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { TodoItem } from './TodoItem';
import { DayDoneButton } from './DayDoneButton';
import { useTodoStore } from '../store/todoStore';
import { useThemeStore } from '../store/themeStore';

interface Props {
  isIcebox?: boolean;
}

export function TodoList({ isIcebox = false }: Props) {
  const { isDark } = useThemeStore();
  const { 
    todos, 
    icebox,
    isLoading,
    error,
    initializeTodos,
    addEmptyTodo,
    toggleTodo, 
    updateTodo, 
    reorderTodos,
    moveToIcebox,
    moveFromIcebox,
    deleteTodo
  } = useTodoStore();

  useEffect(() => {
    initializeTodos();
  }, [initializeTodos]);

  useEffect(() => {
    // Add empty todo in icebox if needed
    if (isIcebox && icebox.every(todo => todo.text)) {
      addEmptyTodo(true);
    }
  }, [isIcebox, icebox, addEmptyTodo]);

  const currentItems = isIcebox ? icebox : todos;
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor)
  );

  if (isLoading) {
    return <div className="text-gray-600 dark:text-gray-300">Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">Error: {error}</div>;
  }

  const getBackgroundClass = (index: number): string => {
    if (isDark) {
      if (index === 0) return 'bg-gray-800/30';
      if (index >= 1 && index <= 3) return 'bg-gray-800/20';
      return 'bg-gray-800/10';
    } else {
      if (index === 0) return 'bg-gray-300/50';
      if (index >= 1 && index <= 3) return 'bg-gray-200/50';
      return 'bg-gray-100/50';
    }
  };

  return (
    <div className="w-full max-w-2xl">
      <h1 className={`text-2xl font-bold mb-8 ${isDark ? 'text-gray-300' : 'text-gray-800'}`}>
        {isIcebox ? 'Ice Box' : 'Today'}
      </h1>
      
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => {
          const { active, over } = event;
          if (over && active.id !== over.id) {
            reorderTodos(active.id as string, over.id as string, isIcebox);
          }
        }}
      >
        <SortableContext
          items={currentItems.map(item => item.id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="space-y-2">
            {currentItems.map((todo, index) => (
              <React.Fragment key={todo.id}>
                <div className={`rounded-lg transition-colors ${!isIcebox ? getBackgroundClass(index) : ''}`}>
                  <TodoItem
                    todo={todo}
                    onToggle={toggleTodo}
                    onUpdate={updateTodo}
                    onMoveToIcebox={!isIcebox ? moveToIcebox : undefined}
                    onMoveFromIcebox={isIcebox ? moveFromIcebox : undefined}
                    onDelete={isIcebox ? deleteTodo : undefined}
                    isIcebox={isIcebox}
                  />
                </div>
                {!isIcebox && (index === 0 || index === 3) && (
                  <div className="h-4" />
                )}
              </React.Fragment>
            ))}
          </div>
        </SortableContext>
      </DndContext>

      {!isIcebox && (
        <div className="mt-8">
          <DayDoneButton />
        </div>
      )}
    </div>
  );
}