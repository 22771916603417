import React from 'react';
import { LogIn, LogOut } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useThemeStore } from '../store/themeStore';

export function AuthButton() {
  const { user, signInWithGoogle, logout, error } = useAuth();
  const { isDark } = useThemeStore();

  // Display any authentication errors
  if (error) {
    console.error('Auth error:', error);
  }

  return (
    <button
      onClick={user ? logout : signInWithGoogle}
      className={`p-2 rounded-md transition-colors ${
        isDark 
          ? 'hover:bg-gray-800 text-gray-400 hover:text-gray-300' 
          : 'hover:bg-gray-100 text-gray-600 hover:text-gray-800'
      }`}
      title={user ? 'Sign out' : 'Sign in with Google'}
    >
      {user ? (
        <LogOut className="h-5 w-5" />
      ) : (
        <LogIn className="h-5 w-5" />
      )}
    </button>
  );
}