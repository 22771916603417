import React from 'react';

export function TenWeekGoalsIcon({ className = "h-6 w-6 text-gray-400" }) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      {/* Center circle */}
      <circle cx="12" cy="12" r="2.5" />
      
      {/* Top-left arrow */}
      <path d="M7 7L4 4l3 3z" />
      <rect x="4" y="4" width="3" height="3" />
      
      {/* Top-right arrow */}
      <path d="M17 7l3-3-3 3z" />
      <rect x="17" y="4" width="3" height="3" />
      
      {/* Bottom-left arrow */}
      <path d="M7 17l-3 3 3-3z" />
      <rect x="4" y="17" width="3" height="3" />
      
      {/* Bottom-right arrow */}
      <path d="M17 17l3 3-3-3z" />
      <rect x="17" y="17" width="3" height="3" />
    </svg>
  );
}