import React from 'react';
import { useTodoStore } from '../store/todoStore';
import { useTrackerStore } from '../store/trackerStore';
import { processDayEnd } from '../utils/dayEndProcessor';

export function DayDoneButton() {
  const { todos } = useTodoStore();
  
  const handleDayDone = async () => {
    await processDayEnd();
  };

  return (
    <button
      onClick={handleDayDone}
      className="mt-8 px-6 py-3 bg-gray-800 hover:bg-gray-700 text-gray-300 rounded-lg transition-colors"
    >
      Day is done
    </button>
  );
}