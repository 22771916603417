// Authentication configuration
export const AUTH_ERRORS = {
  UNAUTHORIZED_DOMAIN: 'auth/unauthorized-domain',
  POPUP_BLOCKED: 'auth/popup-blocked',
  CANCELLED_POPUP: 'auth/cancelled-popup-request',
} as const;

export const AUTH_ERROR_MESSAGES = {
  [AUTH_ERRORS.UNAUTHORIZED_DOMAIN]: 'This domain is not authorized for authentication. Please try again later.',
  [AUTH_ERRORS.POPUP_BLOCKED]: 'The sign-in popup was blocked. Please allow popups and try again.',
  [AUTH_ERRORS.CANCELLED_POPUP]: 'Sign-in was cancelled. Please try again.',
  default: 'An error occurred during authentication. Please try again.',
} as const;