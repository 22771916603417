import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './components/providers/AppProvider';
import { AppRoutes } from './components/routing/AppRoutes';
import { AuthGuard } from './components/AuthGuard';
import { useThemeStore } from './store/themeStore';

export default function App() {
  const { isDark } = useThemeStore();

  return (
    <BrowserRouter>
      <div className={`min-h-screen ${isDark ? 'dark' : ''}`}>
        <div className="min-h-screen bg-gray-50 dark:bg-[#0f0f0f] transition-colors">
          <AuthGuard>
            <AppProvider>
              <AppRoutes />
            </AppProvider>
          </AuthGuard>
        </div>
      </div>
    </BrowserRouter>
  );
}