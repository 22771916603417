import { collection, query, where, orderBy } from 'firebase/firestore';
import { db } from './firebase';
import { auth } from './firebase';

export const createUserQuery = (collectionName: string, isIcebox: boolean = false) => {
  if (!auth.currentUser) return null;
  
  return query(
    collection(db, collectionName),
    where('userId', '==', auth.currentUser.uid),
    where('isIcebox', '==', isIcebox),
    orderBy('position')
  );
};

export const COLLECTIONS = {
  TODOS: 'todos',
  METRICS: 'dailyMetrics'
} as const;