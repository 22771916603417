import { auth } from '../config/firebase';
import { useTodoStore } from '../store/todoStore';
import { useTrackerStore } from '../store/trackerStore';

export async function initializeApp() {
  // Wait for auth to be ready
  await new Promise<void>((resolve) => {
    const unsubscribe = auth.onAuthStateChanged(() => {
      unsubscribe();
      resolve();
    });
  });

  // Initialize stores if user is authenticated
  if (auth.currentUser) {
    const todoStore = useTodoStore.getState();
    const trackerStore = useTrackerStore.getState();
    
    await Promise.all([
      todoStore.initializeTodos(),
      trackerStore.initializeMetrics()
    ]);
  }
}