import React, { useState, useCallback } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { GripVertical } from 'lucide-react';
import { useThemeStore } from '../store/themeStore';

interface Props {
  item: {
    id: string;
    text: string;
  };
  onUpdate: (id: string, text: string) => void;
}

export function FocusItem({ item, onUpdate }: Props) {
  const { isDark } = useThemeStore();
  const [localText, setLocalText] = useState(item.text);
  const [updateTimeout, setUpdateTimeout] = useState<NodeJS.Timeout | null>(null);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value;
    setLocalText(newText);
    
    if (updateTimeout) {
      clearTimeout(updateTimeout);
    }
    
    const timeout = setTimeout(() => {
      onUpdate(item.id, newText);
    }, 500);
    
    setUpdateTimeout(timeout);
  }, [item.id, onUpdate, updateTimeout]);

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`group relative flex items-center gap-4 py-3 px-4 border
        ${isDark ? 'border-gray-700' : 'border-gray-200'}
        ${isDragging ? 'opacity-50 bg-gray-100 dark:bg-[#1a1a1a] rounded-lg' : 'hover:bg-gray-100/50 dark:hover:bg-[#1a1a1a]/50 rounded-lg transition-colors'}`}
    >
      <button
        {...attributes}
        {...listeners}
        className="cursor-grab active:cursor-grabbing p-1 hover:bg-gray-200 dark:hover:bg-gray-800 rounded"
        type="button"
      >
        <GripVertical className={`h-5 w-5 ${isDark ? 'text-gray-600' : 'text-gray-400'}`} />
      </button>

      <input
        type="text"
        value={localText}
        onChange={handleInputChange}
        placeholder="Add a focus for the next 10 weeks"
        className={`flex-1 bg-transparent placeholder-gray-400 dark:placeholder-gray-600 focus:outline-none
          ${isDark ? 'text-gray-300' : 'text-gray-700'}`}
      />
    </div>
  );
}