export function calculateDailyPoints(todos: Todo[]) {
  const points = todos.reduce((sum, todo) => 
    sum + (todo.completed ? todo.points || 0 : 0), 0);
  
  // 1st task (10) + 3 tasks (5 each) + 4 tasks (2 each)
  const maxPoints = 10 + (3 * 5) + (4 * 2);
  
  return { points, maxPoints };
}

export async function recordDailyMetric(
  points: number, 
  maxPoints: number, 
  trackerStore: TrackerStore
) {
  const metric = {
    date: new Date().toISOString().split('T')[0],
    points,
    maxPoints
  };
  
  await trackerStore.addDailyMetric(metric);
}