import React from 'react';
import { AlertCircle } from 'lucide-react';
import { useThemeStore } from '../../store/themeStore';

interface Props {
  message: string;
}

export function AuthError({ message }: Props) {
  const { isDark } = useThemeStore();
  
  return (
    <div className={`flex items-center gap-2 p-4 rounded-lg mb-4 
      ${isDark ? 'bg-red-900/20 text-red-300' : 'bg-red-100 text-red-700'}`}
    >
      <AlertCircle className="h-5 w-5" />
      <p>{message}</p>
    </div>
  );
}