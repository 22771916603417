import { 
  getDocs, 
  addDoc, 
  updateDoc,
  deleteDoc,
  doc,
  collection
} from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import { createUserQuery, COLLECTIONS } from '../config/firestore';
import { Todo } from '../types/todo';

export const todoService = {
  async getTodos(isIcebox: boolean = false) {
    if (!auth.currentUser) return [];
    
    const q = createUserQuery(COLLECTIONS.TODOS, isIcebox);
    if (!q) return [];
    
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Todo[];
  },

  async addTodo(todo: Omit<Todo, 'id'>) {
    if (!auth.currentUser) throw new Error('User not authenticated');
    
    const docRef = await addDoc(collection(db, COLLECTIONS.TODOS), {
      ...todo,
      userId: auth.currentUser.uid,
      createdAt: new Date().toISOString()
    });
    return { id: docRef.id, ...todo };
  },

  async updateTodo(id: string, updates: Partial<Todo>) {
    if (!auth.currentUser) throw new Error('User not authenticated');
    
    const docRef = doc(db, COLLECTIONS.TODOS, id);
    await updateDoc(docRef, {
      ...updates,
      updatedAt: new Date().toISOString()
    });
  },

  async deleteTodo(id: string) {
    if (!auth.currentUser) throw new Error('User not authenticated');
    
    const docRef = doc(db, COLLECTIONS.TODOS, id);
    await deleteDoc(docRef);
  }
};