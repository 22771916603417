import { AUTH_ERRORS, AUTH_ERROR_MESSAGES } from '../config/constants';

export function getAuthErrorMessage(error: any): string {
  if (error?.code && AUTH_ERROR_MESSAGES[error.code as keyof typeof AUTH_ERROR_MESSAGES]) {
    return AUTH_ERROR_MESSAGES[error.code as keyof typeof AUTH_ERROR_MESSAGES];
  }
  return AUTH_ERROR_MESSAGES.default;
}

export function isAuthError(error: any): boolean {
  return error?.code && Object.values(AUTH_ERRORS).includes(error.code);
}